import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { BlockComponent, Header, PageStyle } from '@intervan-ar/ov-componentes';
import { makeAPICall } from '../../core/services/http-request';
import {
  getPaginaInicio,
  getPaginaInicioUsuario,
} from '../../core/parametros/parametros-actions.js';
import { getFullUrl } from '../../core/globals';

function HomePage(props) {
  const { classes, headerProps } = props;
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.auth.user;
  });
  const [cuentas, setCuentas] = useState([]);

  const auth = useSelector(state => {
    return state.auth;
  });

  const parametros = useSelector(state => {
    return state.parametros.data;
  });

  const logoURL = useSelector(state => {
    return state.parametros.data?.OV_APP_LOGO_URL?.valor;
  });

  const [url, setUrl] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (parametros && parametros.loadingParametros !== undefined)
      setLoading(parametros.loadingParametros);
  }, [parametros]);

  /*eslint-disable*/
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setLoading(true);
      Promise.all([
        dispatch(getPaginaInicio()),
        dispatch(getPaginaInicioUsuario()),
      ]).then(data => {
        const param = data[0].length > 0 ? data[0][0] : null;
        if (param && param.valor !== '' && param.valor !== null) setUrl(param.valor);

        setLoading(false);
      });
    } else {
      setLoading(true);
      Promise.all([dispatch(getPaginaInicio())]).then(data => {
        const param = data[0].length > 0 ? data[0][0] : null;
        if (param && param.valor !== '' && param.valor !== null) setUrl(param.valor);

        setLoading(false);
      });
    }
  }, [user]);
  /*eslint-enable*/

  return (
    <div>
      <Header {...headerProps} logoURL={logoURL} showTitle={true}>
        <div></div>
      </Header>
      <BlockComponent blocking={loading}>
        <div className={classes.wrapper}>
          {url !== null && !auth?.popUpLogin && (
            <iframe
              title="main-frame"
              style={{ border: '0px' }}
              src={url}
              width="100%"
              height="100%"
            />
          )}
          {/*user && url === null && !auth?.popUpLogin && (
          )*/}
        </div>
      </BlockComponent>
    </div>
  );
}

HomePage.propTypes = {
  classes: PropTypes.any,
  style: PropTypes.any,
  headerProps: PropTypes.any.isRequired,
};

export default withStyles(PageStyle)(HomePage);
