import { combineReducers } from 'redux';
import auth from './auth/auth-reducer';
import tiposCuentas from './tiposCuentas/tiposCuentas.reducer';
import cuentas from './cuentas/cuentas.reducer';
import configuraciones from './configuraciones/configuraciones.reducer';
import perfil from './perfil/perfil-reducer';
import parametros from './parametros/parametros.reducer';
import mensajes from './mensajes/mensajes-reducer';
import systemReducer from './system/system-reducer';

export const getRootReducer = () => {
  return combineReducers({
    auth,
    tiposCuentas,
    cuentas,
    configuraciones,
    perfil,
    parametros,
    mensajes,
    system: systemReducer,
  });
};
