import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Paper, TextField, Grid, TextareaAutosize } from '@material-ui/core';
import { Header } from '@intervan-ar/ov-componentes';
import { makeStyles } from '@material-ui/core/styles';
import { makeAPICall } from '../../core/services/http-request';
import Moment from 'moment';
import {
  BlockComponent
} from '@intervan-ar/ov-componentes';

const DatosRrhh = props => {
  const { headerProps } = props;
  const [loading, setLoading] = useState(false);
  const [empleado, setEmpleado] = useState({
    cuil: '',
    legajo: '',
    apellido: '',
    nombres: '',
    fecha_ingreso: '',
    regimen: '',
    organizacion: '',
    sector: '',
    cargo: '',
    funcion: '',
    saldo_licencias: ''
  });

  const user = useSelector(state => {
    return state.auth.user;
  });

  const logoURL = useSelector(state => {
    return state.parametros.data?.OV_APP_LOGO_URL?.valor;
  });

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const req = {
        requestConfig: {
          method: 'get',
          params: {
            usuario: user.id,
          },
          url: '/rrhh/empleado',
        },
        methodCall: '',
        needsToken: true,
      };
      const response = await makeAPICall(req);
      if (
        response.data &&
        response.data.length > 0 &&
        response.data[0].hasOwnProperty('legajo')
      )
      setEmpleado(response.data[0]);
      setLoading(false);
    };
    if (user) get();
  }, [user]);

  const useStyles = makeStyles(theme => ({
    personInfo: {
      marginTop: '60px',
      marginBottom: '50px',
      padding: '40px',
    },
    textInput: {
      width: '80%',
      marginLeft: '10px',
      marginBottom: '20px',
    },
  }));
  const classes = useStyles();

  return (
    <Container>
      <Header {...headerProps} logoURL={logoURL} showTitle={true}></Header>
      <BlockComponent blocking={loading}>
        <Paper className={classes.personInfo} elevation={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cuil-input"
                label="CUIL"
                name="cuil"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.cuil}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="legajo-input"
                label="Nro Legajo"
                name="legajo"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.legajo}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id="nombre-input"
                label="Apellido y Nombre"
                name="nombres"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={`${empleado.apellido} ${empleado.nombres}`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="fecha-input"
                label="Fecha Ingreso"
                name="fecha_ingreso"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={
                  empleado.fecha_ingreso
                    ? Moment(empleado.fecha_ingreso).format('DD/MM/YYYY')
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="regimen-input"
                label="Regimen"
                name="regimen"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.regimen}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="organizacion-input"
                label="Organizacion"
                name="organizacion"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.organizacion}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="sector-input"
                label="Sector"
                name="sector"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                                className={classes.textInput}
                value={empleado.sector}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cargo-input"
                label="Cargo"
                name="cargo"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.cargo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="funcion-input"
                label="Funcion"
                name="funcion"
                margin="normal"
                inputProps={
                    { readOnly: true }
                }
                                className={classes.textInput}
                value={empleado.funcion}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField 
                id="saldo-input"
                label="Saldo Licencias(días)"
                name="saldo_licencias"
                margin="normal"
                minRows={5}
                multiline
                inputProps={
                    { readOnly: true }
                }
                className={classes.textInput}
                value={empleado.saldo_licencias}
              />
            </Grid>
          </Grid>
        </Paper>
      </BlockComponent>
    </Container>
  );
};

export default DatosRrhh;
