import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Sidebar from '../components/Sidebar/Sidebar.jsx';
import mainRoutes from '../routes/main';
import PrivateRoute from '../core/auth/PrivateRoute';
import PublicRoute from '../core/auth/PublicRoute';
import { getLogo } from '../core/params.js';
import ServiceOffline from './ServiceOffline.js';
import { browserHistory, getFullUrl } from '../core/globals';

// Style
import style from './MainLayoutStyle';
let ps;

class MainLayout extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    parametros: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      logo: null,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  async componentDidMount() {
    var logo = await getLogo();
    console.log('Logo ' + logo);
    this.setState({ logo: logo });

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  renderRoutes() {
    const componentProps = {
      headerProps: {
        sidebarMinimize: this.sidebarMinimize.bind(this),
        miniActive: this.state.miniActive,
        routes: mainRoutes(this.props.parametros),
        handleDrawerToggle: this.handleDrawerToggle,
        location: this.props.location,
      },
    };
    return (
      <Switch>
        {mainRoutes(this.props.parametros).map((prop, key) => {
          if (!prop.hasOwnProperty('views')) {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            if (prop.private)
              return (
                <PrivateRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  componentProps={componentProps}
                />
              );
            if (prop.public)
              return (
                <PublicRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  componentProps={componentProps}
                />
              );
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          } else {
            const { views } = prop;

            return views.map((route, i) => (
              <PrivateRoute
                path={route.path}
                component={route.component}
                key={i}
                componentProps={componentProps}
              />
            ));
          }
        })}
      </Switch>
    );
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      });
    if (this.props.system && this.props.system.systemOffline) {
      if (
        browserHistory.location.pathname !==
        `${process.env.REACT_APP_BASE_URL}inicio`
      )
        browserHistory.push(getFullUrl('inicio'));
      else return <ServiceOffline />;
    }
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={mainRoutes(this.props.parametros)}
          logo={this.state.logo}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="purple"
          bgColor="white"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          {this.renderRoutes()}
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  parametros: state.parametros.data,
  system: state.system,
}))(withStyles(style)(MainLayout));
