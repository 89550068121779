import {createBrowserHistory} from 'history';

if (window.sessionStorage["esProduccion"]==null){
    console.log('Desarrollo');
    var api = window.sessionStorage['urlApi'];
	var fapi= "AIzaSyBOpb6cibTyC6ioeNANZPJx5BhUzw7SXQI";
	var fdomain= "dinahuapi-intervan.firebaseapp.com";
	var furl= "https://dinahuapi-intervan.firebaseio.com";
	var fpid="dinahuapi-intervan";
	var fbucket="dinahuapi-intervan.appspot.com";
	var fsid="164023751621";
	var faid="1:164023751621:web:0a9ebe9d4871670d";
  	var dmail = window.sessionStorage['usuariodemo']||process?.env?.REACT_APP_DEMO_MAIL;
  	var dpass = window.sessionStorage['clavedemo']||process?.env?.REACT_APP_DEMO_PASSWORD;
}
else {
	if (window.sessionStorage["esProduccion"]==="false"){
		console.log("Demo");
		api="https://backend.intervan.com.ar/ov-mddlwr-test/";
		fapi= "AIzaSyBOpb6cibTyC6ioeNANZPJx5BhUzw7SXQI";
		fdomain= "dinahuapi-intervan.firebaseapp.com";
		furl= "https://dinahuapi-intervan.firebaseio.com";
		fpid="dinahuapi-intervan";
		fbucket="dinahuapi-intervan.appspot.com";
		fsid="164023751621";
		faid="1:164023751621:web:0a9ebe9d4871670d";
		dmail="demo@oficinavirtual.intervan.com.ar";
		dpass="demo2020";
	}
	else {
		console.log("Producccion");
		api="https://backend.intervan.com.ar/ov-mddlwr/";
		fapi= "AIzaSyA-q3dzmwFwJNR-L6K1BXSPqkcGxS-ZANk";
		fdomain= "intervan-oficina-virtual.firebaseapp.com";
		furl= "https://intervan-oficina-virtual.firebaseio.com";
		fpid="intervan-oficina-virtual";
		fbucket="intervan-oficina-virtual.appspot.com";
		fsid="240506318565";
		faid="1:240506318565:web:7c70c941c03bf01c";
		dmail="";
		dpass="";
	}
}

export const API_URL = api || process.env.REACT_APP_API_URL;
//console.log(API_URL);
export const API_ID_EMPRESA = window.sessionStorage["hashid"] || process.env.REACT_APP_API_ID_EMPRESA;
//console.log(API_ID_EMPRESA);
export const BASE_URL = window.sessionStorage["urlRrhh"] || process.env.REACT_APP_BASE_URL;
//console.log(BASE_URL);

export const MODO_ANONIMO = process.env.REACT_APP_MODO_ANONIMO === 'true';
export const ISDEVELOP = process.env.NODE_ENV === 'development';
export const DEMO_MAIL = dmail; //process.env.REACT_APP_DEMO_MAIL || "";
export const DEMO_PASSWORD = dpass; //process.env.REACT_APP_DEMO_PASSWORD || "";

// Globals Menu Configuración
export const FIREBASE_API_KEY = fapi; //process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = fdomain; //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = furl; //process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = fpid; //process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = fbucket; //process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = fsid; //process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = faid; //process.env.REACT_APP_FIREBASE_APP_ID;

// Version Frontend
export const PACKAGEJSON_VERSION =process.env.REACT_APP_COMPILED_VERSION_BACKEND;


// THEME
//export const URL_LOGO = "https://drive.google.com/uc?export=view&id=1mVAUzhySj3JRXRlm_HSJRA_6pFbxn_Nh";
export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR || '#9c27b0';
export const PRIMARY_CONTRAST_TEXT = process.env.REACT_APP_PRIMARY_CONTRAST_TEXT || '#fff';
export const SECONDARY_COLOR = process.env.REACT_APP_SECONDARY_COLOR || '#673ab7';
export const SECONDARY_CONTRAST_TEXT = process.env.REACT_APP_SECONDARY_CONTRAST_TEXT || '#fff';
export const DEFAULT_COLOR = process.env.REACT_APP_DEFAULT_COLOR || '#e0e0e0';
export const DEFAULT_CONTRAST_TEXT = process.env.REACT_APP_DEFAULT_CONTRAST_TEXT || '#999999';
export const INFO_COLOR = process.env.REACT_APP_INFO_COLOR || '#03a9f4';
export const INFO_CONTRAST_TEXT = process.env.REACT_APP_INFO_CONTRAST_TEXT || '#fff';
export const SUCCESS_COLOR = process.env.REACT_APP_SUCCESS_COLOR || '#4caf50';
export const SUCCESS_CONTRAST_TEXT = process.env.REACT_APP_SUCCESS_CONTRAST_TEXT || '#fff';
export const ERROR_COLOR = process.env.REACT_APP_ERROR_COLOR || '#f44336';
export const ERROR_CONTRAST_TEXT = process.env.REACT_APP_ERROR_CONTRAST_TEXT || '#fff';


// @WARN: Only use this one if you don't have a way to make the call from a component that has a router context
export const browserHistory = createBrowserHistory();

export const getFullUrl = (url='') => {
	return `${BASE_URL}${url}`;
};

