import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import PermIdentityTwoToneIcon from '@material-ui/icons/PermIdentityTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import EmojiTransportationTwoToneIcon from '@material-ui/icons/EmojiTransportationTwoTone';
import BallotTwoToneIcon from '@material-ui/icons/BallotTwoTone';

// core components
import avatar from '../../assets/img/default-avatar.png';
import {
  login,
  logout,
  signInWithFacebookProvider,
  signInWithGoogleProvider,
  register,
  resetPassword,
  isLoggedIn,
} from '../../core/auth/auth-actions';
import * as AuthService from '../../core/auth/auth-actions';
import {
  LoginComponent,
  ResetComponent,
  RegisterComponent,
  ConfirmItemComponent,
  MessageComponent,
} from '@intervan-ar/ov-componentes';
import { getFullUrl } from '../../core/globals';

// Styles
import sidebarStyle from './SidebarStyle.jsx';

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  static defaultProps = {};

  static propTypes = {
    classes: PropTypes.object.isRequired,
    logo: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      openConfirm: false,
      message: '',
      messageColor: '',
      messageSegs: 3000,
      openAvatar: false,
      miniActive: true,
      showLoginComponent: false,
      showRegisterComponent: false,
      showResetComponent: false,
      errorMessage: '',
      registering: false,
      reseting: false,
      popUpLogin: false,
    };
    this.activeRoute.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onResetClick = this.onResetClick.bind(this);
    this.onCancelRegisterClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onLogin.bind(this);
    this.onCancelResetClick.bind(this);
  }

  setErrorMessage(message) {
    this.setMessage({ messageColor: 'error', message });
  }
  setMessage = ({ message, messageColor, messageSegs }) => {
    this.setState({ message, messageColor, messageSegs });
    setTimeout(this.resetMessage, messageSegs || 3000);
  };
  resetMessage = () => {
    this.setState({ message: null, messageColor: null });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  onLogout = () => {
    this.setState({ openConfirm: false });
    this.props.actions.logout();
    this.props.history.replace(getFullUrl('login'));
  };

  onMyProfile = () => {
    this.props.history.replace(getFullUrl('my-profile'));
  };

  onCuentas = () => {
    this.props.history.replace(getFullUrl('cuentas'));
  };

  checkPermissions = (prop, user) => true;

  hasChildrenToShow = (children, user) => {
    let childrenToShow = false;
    children.map(prop => {
      return (childrenToShow |= this.checkPermissions(prop, user));
    });
    return childrenToShow;
  };

  onLoginClick() {
    this.setState({
      showRegisterComponent: false,
      showLoginComponent: true,
      showResetComponent: false,
      errorMessage: '',
    });
  }

  onCancelClick() {
    this.setState({
      showLoginComponent: false,
      showRegisterComponent: false,
      showResetComponent: false,
    });
  }

  onLogin = (email, password) => {
    if (this.props.actions.login)
      return this.props.actions
        .login(email, password)
        .then(this.onCancelClick.bind(this))
        .catch(error => {
          this.setState({ errorMessage: error.message });
        });
  };

  onLoginWithFacebookProvider = () => {
    if (this.props.actions.signInWithFacebookProvider)
      return this.props.actions
        .signInWithFacebookProvider()
        .then(this.onCancelClick.bind(this))
        .catch(error => {
          this.setState({ errorMessage: error.message });
        });
  };

  onLoginWithGoogleProvider = () => {
    if (this.props.actions.signInWithGoogleProvider)
      return this.props.actions
        .signInWithGoogleProvider()
        .then(this.onCancelClick.bind(this))
        .catch(error => {
          this.setState({ errorMessage: error.message });
        });
  };

  onRegisterClick() {
    this.setState({
      showRegisterComponent: true,
      showLoginComponent: false,
      showResetComponent: false,
      errorMessage: '',
    });
  }

  onCancelRegisterClick() {
    this.setState({
      showRegisterComponent: false,
      showLoginComponent: true,
      showResetComponent: false,
    });
  }

  onRegister = (email, name, password) => {
    if (this.props.actions.register)
      this.setState({ registering: true, errorMessage: '' });
    return this.props.actions
      .register(email, name, password)
      .then(this.onCancelClick.bind(this))
      .then(() => {
        this.setMessage({
          message:
            'Se ha enviado un mail de verificación a su casilla de correo. Por favor verifique confirme su casilla haciendo click en el link enviado y luego vuelva a ingresar al sistema',
          messageColor: 'info',
          messageSegs: 30000,
        });
        this.setState({
          registering: false,
        });
      })
      .catch(error => {
        this.setState({ registering: false, errorMessage: error.message });
      });
  };

  onResetClick(email) {
    this.setState({
      showRegisterComponent: false,
      showLoginComponent: false,
      showResetComponent: true,
      errorMessage: '',
    });
  }

  onCancelResetClick() {
    this.setState({
      showRegisterComponent: false,
      showLoginComponent: true,
      showResetComponent: false,
    });
  }

  onResetPassword = email => {
    if (this.props.actions.resetPassword) {
      this.setState({ reseting: true, errorMessage: '' });
      return this.props.actions
        .resetPassword(email)
        .then(this.onCancelClick.bind(this))
        .then(() => {
          this.setMessage({
            message: 'Se ha enviado un correo para restablecer la contraseña.',
            messageColor: 'success',
          });
        })
        .then(() => this.setState({ reseting: false }))
        .catch(error => {
          this.setState({ reseting: false, errorMessage: error.message });
        });
    } else {
      console.log('onResetPassword no esta definida');
    }
  };

  componentDidUpdate() {
    if (
      this.props.popUpLogin &&
      !this.state.popUpLogin &&
      !this.state.showLoginComponent
    ) {
      this.setState({ ...this.state, popUpLogin: true });
      this.onLoginClick();
    }
  }

  render() {
    const { classes, logo, logoText, routes, user, loading } = this.props;

    const standalone = localStorage.getItem('standalone')
      ? localStorage.getItem('standalone').toUpperCase()
      : null;
    const labelSalir = this.props.parametros?.AU_LABEL_SALIR?.value || 'Salir';
    const labelPerfil =
      this.props.parametros?.AU_LABEL_PERFIL?.value || 'Mi Perfil';

    const {
      showLoginComponent,
      errorMessage,
      showRegisterComponent,
      showResetComponent,
      registering,
      reseting,
    } = this.state;
    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      ' ' +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const userWrapperClass = classes.user + ' ' + classes.drawerBackgroundAfter;
    const caret = classes.caret;
    const photo = classes.photo;
    const fullname = this.props.user && this.props.user.nombre;
    const foto = this.props.user.fotoUrl;
    const MENU_DESC_CUENTAS = this.props.parametros.REACT_APP_MENU_DESC_CUENTAS
      ? this.props.parametros.REACT_APP_MENU_DESC_CUENTAS.valor
      : 'Cuentas';
    let userContainer = <div></div>;

    const iconoCuentas =
      this.props.parametros.REACT_APP_MENU_ICON_CUENTAS &&
      this.props.parametros.REACT_APP_MENU_ICON_CUENTAS.valor ===
        'municipios' ? (
        <EmojiTransportationTwoToneIcon />
      ) : this.props.parametros.REACT_APP_MENU_ICON_CUENTAS &&
        this.props.parametros.REACT_APP_MENU_ICON_CUENTAS.valor ===
          'creditos' ? (
        <BallotTwoToneIcon />
      ) : (
        <AccountTreeTwoToneIcon />
      );
    if (isLoggedIn()) {
      userContainer = (
        <div className={userWrapperClass}>
          <div className={photo}>
            <img src={foto || avatar} className={classes.avatarImg} alt="..." />
          </div>
          <List className={classes.list}>
            <ListItem className={classes.item + ' ' + classes.userItem}>
              <NavLink
                to={'#'}
                className={classes.itemLink + ' ' + classes.userCollapseButton}
                onClick={() => this.openCollapse('openAvatar')}
              >
                <ListItemText
                  primary={fullname}
                  secondary={
                    <b
                      className={
                        caret +
                        ' ' +
                        classes.userCaret +
                        ' ' +
                        (this.state.openAvatar ? classes.caretActive : '')
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText + ' ' + classes.userItemText}
                />
              </NavLink>
              <Collapse in={this.state.openAvatar} unmountOnExit>
                <List className={classes.list + ' ' + classes.collapseList}>
                  <ListItem
                    className={classes.collapseItem}
                    onClick={this.onMyProfile}
                  >
                    <NavLink
                      to={getFullUrl('myprofile')}
                      className={classes.itemLink}
                    >
                      <ListItemIcon
                        className={classes.itemIcon}
                        style={{ top: 0 }}
                      >
                        <Icon color="primary">
                          <PermIdentityTwoToneIcon />
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={labelPerfil}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                  <ListItem
                    className={classes.collapseItem}
                    onClick={() => {
                      standalone === 'N'
                        ? window.close()
                        : this.setState({ openConfirm: true });
                    }}
                  >
                    <NavLink to="#" className={classes.itemLink}>
                      <ListItemIcon
                        className={classes.itemIcon}
                        style={{ top: 0 }}
                      >
                        <Icon color="primary">
                          <HighlightOffTwoToneIcon />
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={standalone === 'N' ? 'Volver' : labelSalir}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                </List>
              </Collapse>
            </ListItem>
          </List>
        </div>
      );
    } else {
      userContainer = (
        <div className={userWrapperClass}>
          <NavLink
            to="#"
            onClick={this.onLoginClick.bind(this)}
            className={classes.itemLink}
          >
            <ListItemIcon className={classes.itemIcon} style={{ top: 0 }}>
              <Icon color="primary">
                <PersonOutlineTwoToneIcon />
              </Icon>
            </ListItemIcon>
            <ListItemText
              primary={'Ingresar'}
              disableTypography={true}
              className={collapseItemText}
            />
          </NavLink>
        </div>
      );
    }
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          const disabled = prop.disabled || false;

          if (prop.redirect) return null;
          if (prop.notShowOnMenu) return null;
          if (prop.private && !isLoggedIn()) return null;
          if (prop.collapse) {
            if (!this.hasChildrenToShow(prop.views, user)) return null;
            const navLinkClasses =
              classes.itemLink +
              ' ' +
              cx({
                [' ' + classes.collapseActive]: this.activeRoute(prop.path),
              });
            const itemText =
              classes.itemText +
              ' ' +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
              });
            const collapseItemText =
              classes.collapseItemText +
              ' ' +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
              });
            const itemIcon = classes.itemIcon;
            const caret = classes.caret;
            return (
              <ListItem key={key} className={classes.item} disabled={disabled}>
                <NavLink
                  to={'#'}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === 'string' ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon color="primary" />
                    )}
                  </ListItemIcon>
                  <Tooltip title={prop.tooltip || ''} placement="right-start">
                    <ListItemText
                      primary={prop.name}
                      secondary={
                        <b
                          className={
                            caret +
                            ' ' +
                            (this.state[prop.state] ? classes.caretActive : '')
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText}
                    />
                  </Tooltip>
                </NavLink>

                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + ' ' + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      const disabled = prop.disabled || false;
                      if (!this.checkPermissions(prop, user)) return null;
                      const navLinkClasses =
                        classes.collapseItemLink +
                        ' ' +
                        cx({
                          [' ' + classes.optionColor]: this.activeRoute(
                            prop.path
                          ),
                        });
                      return (
                        <ListItem
                          key={key}
                          className={classes.collapseItem}
                          disabled={disabled}
                        >
                          <NavLink
                            to={disabled ? '#' : prop.path}
                            className={navLinkClasses}
                          >
                            <ListItemIcon
                              className={itemIcon}
                              style={{ top: 0 }}
                            >
                              {typeof prop.icon === 'string' ? (
                                <Icon>{prop.icon}</Icon>
                              ) : (
                                <prop.icon color="primary" />
                              )}
                            </ListItemIcon>
                            <Tooltip
                              title={prop.tooltip || ''}
                              placement="right-start"
                            >
                              <ListItemText
                                primary={prop.name}
                                disableTypography={true}
                                className={collapseItemText}
                              />
                            </Tooltip>
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          if (!this.checkPermissions(prop, user)) return null;
          const navLinkClasses =
            classes.itemLink +
            ' ' +
            cx({
              [' ' + classes.optionColor]: this.activeRoute(prop.path),
            });
          const itemText =
            classes.itemText +
            ' ' +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
            });
          const itemIcon = classes.itemIcon;
          return (
            <ListItem key={key} className={classes.item} disabled={disabled}>
              <NavLink
                to={disabled ? '#' : prop.path}
                className={navLinkClasses}
              >
                <ListItemIcon
                  className={
                    this.activeRoute(prop.path)
                      ? classes.itemIconActive
                      : itemIcon
                  }
                >
                  {typeof prop.icon === 'string' ? (
                    <Icon color="primary">{prop.icon}</Icon>
                  ) : (
                    <prop.icon color="primary" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const logoMini =
      classes.logoMini +
      ' ' +
      cx({
        [classes.logoMiniSidebarMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const logoImg =
      classes.img +
      ' ' +
      cx({
        [classes.imgSidebarMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const logoClasses = classes.logo + ' ' + classes.drawerBackgroundAfter;
    var brand = (
      <div className={logoClasses}>
        <a href={getFullUrl('')} className={logoMini}>
          <img src={logo} alt="" className={logoImg} />
        </a>
        {logoText && (
          <a href={getFullUrl('')} className={logoNormal}>
            {logoText}
          </a>
        )}
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div>
        {showLoginComponent && (
          <LoginComponent
            open={showLoginComponent}
            onCancelClick={this.onCancelClick}
            onLogin={this.onLogin}
            onLoginWithGoogleProvider={this.onLoginWithGoogleProvider}
            onLoginWithFacebookProvider={this.onLoginWithFacebookProvider}
            loading={loading}
            errorMessage={errorMessage}
            onCreateAccountClick={this.onRegisterClick}
            onResetPasswordClick={this.onResetClick}
            isLoggedIn={isLoggedIn}
          />
        )}
        {showRegisterComponent && (
          <RegisterComponent
            open={showRegisterComponent}
            onCancelClick={this.onCancelClick}
            onRegister={this.onRegister}
            loading={loading || registering}
            errorMessage={errorMessage}
            onLoginClick={this.onLoginClick}
            isLoggedIn={isLoggedIn}
          />
        )}
        {showResetComponent && (
          <ResetComponent
            open={showResetComponent}
            onCancelClick={this.onCancelClick}
            onResetPassword={this.onResetPassword}
            loading={loading || reseting}
            errorMessage={errorMessage}
            onResetClick={this.onResetClick}
            onLoginClick={this.onLoginClick}
            isLoggedIn={isLoggedIn}
          />
        )}
        <div ref="mainPanel">
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={'right'}
              open={this.props.open}
              classes={{ paper: drawerPaper + ' ' + classes.drawerBackground }}
              onClose={this.props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {brand}
              <SidebarWrapper
                className={sidebarWrapper}
                user={userContainer}
                links={links}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              onMouseOver={() => this.setState({ miniActive: false })}
              onMouseOut={() => this.setState({ miniActive: true })}
              anchor={'left'}
              variant="permanent"
              open
              classes={{ paper: drawerPaper + ' ' + classes.drawerBackground }}
            >
              {brand}
              <SidebarWrapper
                className={sidebarWrapper}
                user={userContainer}
                links={links}
              />
            </Drawer>
          </Hidden>
        </div>

        <MessageComponent
          color={this.state.messageColor}
          message={this.state.message}
          segs={this.state.messageSegs}
        />
        <ConfirmItemComponent
          open={this.state.openConfirm}
          item="logout"
          handleClose={() => this.setState({ openConfirm: false })}
          handleOnConfirm={item => this.onLogout()}
          title="Salir"
          description="¿Seguro que desea salir?"
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    // mapStateToProps
    user: state.auth.user,
    loading: state.auth.loadingUser,
    parametros: state.parametros.data,
    initialMessage: state.auth.user.showInitialMessage,
    popUpLogin: state.auth.popUpLogin ? state.auth.popUpLogin : false,
  }),
  dispatch => ({
    // mapDispatchToProps
    actions: bindActionCreators(
      {
        login,
        signInWithFacebookProvider,
        signInWithGoogleProvider,
        logout,
        register,
        resetPassword,
      },
      dispatch
    ),
  })
)(withStyles(sidebarStyle)(Sidebar));
