import { createActions } from "redux-actions";

export const {getParametro, getVersion, getPaginaInicio, getPaginaInicioUsuario} = createActions({
    'GET_PARAMETRO': (params) => ({
        API: {
          endpoint: {
            url:`/servicios/parametros`,
            method: 'get',
            params
          },
        needsToken: false, 
        }
      }),
    'GET_VERSION': (params) => ({
      API: {
        endpoint: {
          url:`/servicios/version`,
          method: 'get',
          params
        },
        needsToken: false, 
      }
    }),
    'GET_PAGINA_INICIO':()=>({
      API: {
        endpoint: {
          url:`/servicios/parametros`,
          method: 'get',
          params: {parametro:'AU_PAGINA_INICIO_RRHH'}
        },
        needsToken: false, 
      }
    }),
    'GET_PAGINA_INICIO_USUARIO':()=>({
      API: {
        endpoint: {
          url:`/servicios/parametros`,
          method: 'get',
          params: {parametro:'AU_PAGINA_INICIO_USUARIO_RRHH'}
        },
        needsToken: false, 
      }
    }),
});
