import { handleActions } from 'redux-actions';
import * as actionTypes from './auth-actions';

const initialState = {
  token: 0,
  user: {},
  errorMessage: '',
  loadingUser: false,
  popUpLogin: false,
};

const mapUser = user => {
  if (!user) return {};
  return {
    nombre: user && (user.nombre || user.email),
    id: user && user.id,
    fotoUrl: user.fotoUrl,
  };
};

export default handleActions(
  {
    [actionTypes.AUTH_LOGIN]: state => {
      return { ...state, loadingUser: true, popUpLogin: false };
    },
    [actionTypes.AUTH_LOGIN_OK]: (state, action) => {
      return { ...state, token: action.payload.token, popUpLogin: false };
    },
    [actionTypes.AUTH_LOGIN_FAIL]: (state, action) => {
      return {
        ...state,
        token: '',
        errorMessage: action.payload.message,
        loadingUser: false,
      };
    },
    [actionTypes.AUTH_USER_INFO]: (state, action) => {
      const user = mapUser(action.payload);
      return { ...state, user, loadingUser: false };
    },
    [actionTypes.AUTH_LOGOUT]: (state, action) => {
      return { ...initialState, popUpLogin: action.payload.popUpLogin };
    },
  },
  initialState
);
