//import { connect } from 'react-redux'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import Person from '@material-ui/icons/Person';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomePage from '../views/Home/HomePage';
import MyProfile from '../views/User/MyProfile';
import DatosRhh from '../views/Rrhh/datos';
import Recibos from '../views/Rrhh/recibos';
import { getFullUrl, MODO_ANONIMO } from '../core/globals';
const defaultPath = 'inicio';

const routes = (parametros = {}) => {
  /*
		Parametros contiene la configuracion de todas las opciones del menú
		Basicamente se configuran dos opciones por opcion de menu con el siguiente formato:
		MENU_SHOW_opcion y MENU_ACTIVE_opcion que configuran si la opcion se muestra y esta activa
		respectivamente.
		Estas opciones se consulta cuando el usuario se loguea exitosamente.
		De lo contrario ó si el paremtro de configuracion no se encuentra, se consideran como false
	*/
  const MENU_SHOW_RRHH_DATOS = true;
  const MENU_ACTIVE_RRHH_DATOS = true;
  const MENU_DESC_RRHH_DATOS = parametros.REACT_APP_MENU_DESC_RRHH_DATOS
    ? parametros.REACT_APP_MENU_DESC_RRHH_DATOS.valor
    : 'Datos Legajo';

  const MENU_SHOW_RRHH_RECIBOS = true;
  const MENU_ACTIVE_RRHH_RECIBOS = true;
  const MENU_DESC_RRHH_RECIBOS = parametros.REACT_APP_MENU_DESC_RRHH_RECIBOS
    ? parametros.REACT_APP_MENU_DESC_RRHH_RECIBOS.valor
    : 'Recibos de Sueldo';

  return [
    {
      path: getFullUrl('inicio'),
      name: 'Inicio',
      component: HomePage,
      public: true,
      icon: HomeTwoToneIcon,
    },
    {
      path: getFullUrl('my-profile'),
      name: 'Mi Perfil',
      component: MyProfile,
      public: true,
      private: false,
      notShowOnMenu: true,
      icon: Person,
    },
    {
      path: getFullUrl('datos'),
      name: MENU_DESC_RRHH_DATOS,
      component: DatosRhh,
      public: true,
      private: true,
      disabled: !MENU_ACTIVE_RRHH_DATOS,
      notShowOnMenu: !MENU_SHOW_RRHH_DATOS,
      icon: AccountBoxIcon,
    },
    {
      path: getFullUrl('recibos'),
      name: MENU_DESC_RRHH_RECIBOS,
      component: Recibos,
      public: true,
      private: true,
      disabled: !MENU_ACTIVE_RRHH_RECIBOS,
      notShowOnMenu: !MENU_SHOW_RRHH_RECIBOS,
      icon: ReceiptIcon,
    },
    {
      redirect: true,
      path: getFullUrl(),
      pathTo: getFullUrl(defaultPath),
      name: 'Inicio',
    },
  ];
};

export default routes;
