import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPerfil,
  getTipoDocumento,
  postPerfil,
} from '../../core/perfil/perfil-actions';
import { Profile } from '@intervan-ar/ov-views';
import {
  BlockComponent,
  Header,
  MessageComponent,
} from '@intervan-ar/ov-componentes';

function MyProfile(props) {
  const { classes, headerProps } = props;
  //const loadingPerfil = props.data.loadingPerfil;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.auth.user;
  });
  const userPerfil = useSelector(state => {
    return state.perfil.data;
  });
  const parametros = useSelector(state => {
    return state.parametros.data;
  });
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [message, setMessage] = useState({
    message: null,
    color: null,
  });

  /*eslint-disable*/
  useEffect(() => {
    setLoading(true);
    const usuario = user.id;
    Promise.all([
      dispatch(getPerfil({ usuario })),
      dispatch(getTipoDocumento()),
    ]).then(data => {
      setTiposDocumentos(data[1] || []);
      setLoading(false);
    });
  }, [user]);
  /*eslint-enable*/

  const onSavePerfil = newPerfil => {
    newPerfil['usuario'] = user.id;
    setLoading(true);
    Promise.all([dispatch(postPerfil(newPerfil))])
      .then(data => {
        setMessage({
          color: 'success',
          message: 'Perfil actualizado.',
        });
        setLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        //setMessage({ message: error.message || error, color: "error" });
        setLoading(false);
      });
    return false;
  };

  //console.log("perfil", userPerfil);
  //console.log("tiposDocumentos", tiposDocumentos);
  //console.log("classes", classes);

  //if (tiposDocumentos.length === 0)
  //  return <h2>Necesita definir los tipos de documentos</h2>;

  if (!tiposDocumentos || tiposDocumentos.length === 0)
    return (
      <BlockComponent blocking={loading}>
        <Header {...headerProps} showTitle={true}>
          <div></div>
        </Header>
      </BlockComponent>
    );

  return (
    <BlockComponent blocking={loading}>
      <MessageComponent {...message} segs={1000} />
      <Profile
        classes={classes}
        headerProps={headerProps}
        parametros={parametros}
        tipos_documentos={tiposDocumentos}
        data={userPerfil}
        id={user.id}
        onSavePerfil={onSavePerfil}
      />
    </BlockComponent>
  );
}
export default MyProfile;
