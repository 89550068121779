import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { browserHistory } from '../core/globals';
import { getStore } from '../core/store';
import {
  setDeps,
  logout,
  loginFromLocalStorage,
} from '../core/auth/auth-actions';
import indexRoutes from '../routes';
import { SpinnerComponent } from '@intervan-ar/ov-componentes';
import { initializeAPI } from '../core/services/http-request';
import { customTheme } from '../customTheme';
import { getColor } from '../core/params.js';

import {
  getVersion,
  getParametro,
} from '../core/parametros/parametros-actions.js';
import { PACKAGEJSON_VERSION, ISDEVELOP } from '../core/globals.js';

const store = getStore();
setDeps(browserHistory);
initializeAPI(() => store.dispatch(logout()));

export default class App extends React.Component {
  verificarVersiones = (versionFrontend, versionBackend) => {
    const frontend = versionFrontend.split('.');
    const backend = versionBackend.split('.');
    //Primer dígito
    if (parseInt(frontend[0]) !== parseInt(backend[0])) {
      if (parseInt(frontend[0]) < parseInt(backend[0]))
        return {
          ok: false,
          message: 'Debe actualizar la Aplicación',
          versionFrontend,
          versionBackend,
        };
      if (parseInt(frontend[0]) > parseInt(backend[0]))
        return {
          ok: false,
          message:
            'El contenedor de servicios es de versión ' +
            versionBackend +
            ' y la versión de la aplicación es ' +
            versionFrontend +
            ', por favor contacte al administrador.',
          versionFrontend,
          versionBackend,
        };
    }
    if (parseInt(frontend[1]) !== parseInt(backend[1])) {
      if (parseInt(frontend[1]) < parseInt(backend[1]))
        return {
          ok: false,
          message: 'Debe actualizar la Aplicación',
          versionFrontend,
          versionBackend,
        };
      if (parseInt(frontend[1]) > parseInt(backend[1]))
        return {
          ok: false,
          message:
            'El contenedor de servicios es de versión ' +
            versionBackend +
            ' y la versión de la aplicación es ' +
            versionFrontend +
            ', por favor contacte al administrador.',
          versionFrontend,
          versionBackend,
        };
    }
    return {
      ok: true,
      message:
        'Version del Frontend ' +
        versionFrontend +
        ', Backend ' +
        versionBackend,
      versionFrontend,
      versionBackend,
    };
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('standalone'))
      localStorage.setItem('standalone', urlParams.get('standalone'));

    //const icon = document.getElementById('favicon');
    //icon.href = 'https://www.google.com/favicon.ico';
  }

  async componentWillMount() {
    this.setState({ loading: true });
    var color = await getColor();
    console.log('Color ' + color);
    customTheme.palette.primary.main = color;
    //Checkeo de Versiones
    new Promise((resolve, reject) => {
      //store.dispatch(getVersion({versionApp:"version"}))
      store.dispatch(getParametro({ parametro: 'AU_NOTICIAS' }));

      //Si es desarrollo saltea el checkeo de versiones
      if (ISDEVELOP) resolve({});

      new Promise((resolve, reject) => {
        resolve(store.dispatch(getVersion({ versionApp: 'version' })));
      }).then(result => {
        //comparar la version
        //Si la version no es la correct reject
        const verificacion = this.verificarVersiones(
          PACKAGEJSON_VERSION,
          result.version
        );
        verificacion.ok ? resolve() : reject(verificacion);
      });
    })
      .then(result => {
        store.dispatch(loginFromLocalStorage()).then(() => {
          this.setState({ loading: false });
        });
      })
      .catch(error => {
        this.setState({ loading: true });
        console.log(error);
        alert(error.message);
      });
  }

  render() {
    const theme = createTheme(customTheme);
    if (this.state.loading)
      return (
        <SpinnerComponent
          blocking={true}
          style={{ minHeight: 400, height: '100%' }}
        />
      );
    return (
      <Provider store={store}>
        <Router history={browserHistory}>
          <ThemeProvider theme={theme}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </ThemeProvider>
        </Router>
      </Provider>
    );
  }
}
